import { Scene, WebGLRenderer, PerspectiveCamera, PlaneGeometry, MeshBasicMaterial, Mesh, DoubleSide, BoxGeometry, OrthographicCamera, ShaderMaterial, Vector2, Vector3 } from "three";


export async function main(canvas?: HTMLCanvasElement) {

// language=GLSL
    const fragment = `
        uniform float u_time;
        uniform float u_threshold;
        uniform vec2 u_resolution;

        uniform float u_scale;
        uniform float u_scrollpos;

        uniform vec3 u_color1;
        uniform vec3 u_color2;
        uniform vec3 u_color3;

        uniform float u_intensity1;
        uniform float u_intensity2;
        uniform float u_intensity3;

        uniform float u_specular1;
        uniform float u_specular2;
        uniform float u_specular3;

        uniform vec3 u_direction1;
        uniform vec3 u_direction2;
        uniform vec3 u_direction3;

        uniform float u_steepness;
        uniform float u_snip;

        vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
        vec2 mod289(vec2 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
        vec3 permute(vec3 x) { return mod289(((x*34.0)+1.0)*x); }

        float snoise(vec2 v) {
            const vec4 C = vec4(0.211324865405187, // (3.0-sqrt(3.0))/6.0
            0.366025403784439, // 0.5*(sqrt(3.0)-1.0)
            -0.577350269189626, // -1.0 + 2.0 * C.x
            0.024390243902439);// 1.0 / 41.0
            vec2 i  = floor(v + dot(v, C.yy));
            vec2 x0 = v -   i + dot(i, C.xx);
            vec2 i1;
            i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
            vec4 x12 = x0.xyxy + C.xxzz;
            x12.xy -= i1;
            i = mod289(i);// Avoid truncation effects in permutation
            vec3 p = permute(permute(i.y + vec3(0.0, i1.y, 1.0))
            + i.x + vec3(0.0, i1.x, 1.0));

            vec3 m = max(0.5 - vec3(dot(x0, x0), dot(x12.xy, x12.xy), dot(x12.zw, x12.zw)), 0.0);
            m = m*m;
            m = m*m;
            vec3 x = 2.0 * fract(p * C.www) - 1.0;
            vec3 h = abs(x) - 0.5;
            vec3 ox = floor(x + 0.5);
            vec3 a0 = x - ox;
            m *= 1.79284291400159 - 0.85373472095314 * (a0*a0 + h*h);
            vec3 g;
            g.x  = a0.x  * x0.x  + h.x  * x0.y;
            g.yz = a0.yz * x12.xz + h.yz * x12.yw;
            return 130.0 * dot(m, g);
        }

        float heightMap(vec2 pos) {
            vec2 scaledPos = pos * vec2(0.001);

            float DF = 0.0;

            // Add a random position
            float a = 0.0;
            vec2 vel = vec2(u_time*.1);
            DF += snoise(scaledPos+vel)*.25+.25;

            // Add a random position
            a = snoise(scaledPos*vec2(cos(u_time*0.15), sin(u_time*0.1))*0.1)*3.1415;
            vel = vec2(cos(a), sin(a));
            DF += snoise(scaledPos+vel)*.25+.25;

            return DF;
        }

        float map(float value, float min1, float max1, float min2, float max2) {
            return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
        }

        float mappedHeight(vec2 uv) {
            float height = heightMap(uv);
            float mapped = map(height, u_threshold, 0.9, 0.0, 1.0);
            return pow(mapped, u_steepness);
        }

        vec3 getNormals(vec2 uv) {
            vec2 ste = 1. / u_resolution;
            float height = mappedHeight(uv);
            vec3 norms = normalize(-vec3(vec2(dFdx(height), dFdy(height)) * u_resolution, -1.));
            return normalize(norms * vec3(1., 1., 1.));
        }

        void main() {
            vec2 pos = gl_FragCoord.xy * vec2(u_scale) - vec2(0.0, u_scrollpos);
            float height = heightMap(pos);
            float mask = 1.0 - step(height, u_threshold + u_snip);
            vec3 normals = getNormals(pos);
            vec3 viewDir = normalize(vec3(0.0, 0.0, 1.0));

            vec3 reflectDir1 = reflect(-u_direction1, normals);
            float spec1 = pow(max(dot(viewDir, reflectDir1), 0.0), u_specular1);
            vec3 specular1 = u_intensity1 * spec1 * u_color1;

            vec3 reflectDir2 = reflect(-u_direction2, normals);
            float spec2 = pow(max(dot(viewDir, reflectDir2), 0.0), u_specular2);
            vec3 specular2 = u_intensity2 * spec2 * u_color2;

            vec3 reflectDir3 = reflect(-u_direction3, normals);
            float spec3 = pow(max(dot(viewDir, reflectDir3), 0.0), u_specular3);
            vec3 specular3 = u_intensity3 * spec3 * u_color3;

            gl_FragColor = vec4((specular1 + specular2 + specular3) * mask, 1.0);
        }
    `

    const scene = new Scene();
    const camera = new OrthographicCamera(-1, 1, 1, -1);

    const paused = {
        paused: false,
    }

    const palette: { [key: string]: [number, number, number] } = {
        color1: [161, 194, 100],
        color2: [67, 206, 235],
        color3: [57, 61, 159],
    };

    const direction: { [key: string]: { theta: number, phi: number } } = {
        direction1: {
            theta: 331.0,
            phi: 58.0,
        },
        direction2: {
            theta: 89.0,
            phi: 68.0,
        },
        direction3: {
            theta: 237.0,
            phi: 46.0,
        },
    };

    let scrollAmount = 0;
    let smoothedScroll = 0;

    const material = new ShaderMaterial({
        fragmentShader: fragment,
        uniforms: {
            u_time: {value: 0},
            u_threshold: {value: 0.6},
            u_resolution: {value: new Vector2()},

            u_scale: {value: 2.5},
            u_scrollpos: {value: 0.0},

            u_color1: {value: new Vector3()},
            u_direction1: {value: new Vector3()},
            u_intensity1: {value: 1.2},
            u_specular1: {value: 9.0},

            u_color2: {value: new Vector3()},
            u_direction2: {value: new Vector3()},
            u_intensity2: {value: 1.6},
            u_specular2: {value: 9.0},

            u_color3: {value: new Vector3()},
            u_direction3: {value: new Vector3()},
            u_intensity3: {value: 1.0},
            u_specular3: {value: 12.0},

            u_steepness: {value: 0.01},
            u_snip: {value: 0.02},
        }
    });

    function colorCallback(id: string) {
        material.uniforms["u_" + id].value.x = palette[id][0] / 255;
        material.uniforms["u_" + id].value.y = palette[id][1] / 255;
        material.uniforms["u_" + id].value.z = palette[id][2] / 255;
    }

    function directionCallback(id: string) {
        material.uniforms["u_" + id].value.x = Math.sin(direction[id].phi * (Math.PI / 180)) * Math.cos(direction[id].theta * (Math.PI / 180));
        material.uniforms["u_" + id].value.y = Math.sin(direction[id].phi * (Math.PI / 180)) * Math.sin(direction[id].theta * (Math.PI / 180));
        material.uniforms["u_" + id].value.z = Math.cos(direction[id].phi * (Math.PI / 180));
        console.log(material.uniforms["u_" + id].value.x);
    }

    colorCallback("color1");
    colorCallback("color2")
    colorCallback("color3");
    directionCallback("direction1");
    directionCallback("direction2")
    directionCallback("direction3");

    const renderer = new WebGLRenderer({
        antialias: true,
        canvas: canvas,
    });

    if (canvas) {
        renderer.setSize(canvas.clientWidth, canvas.clientHeight);
        material.uniforms.u_resolution.value.x = canvas.clientWidth;
        material.uniforms.u_resolution.value.y = canvas.clientHeight;
        camera.updateProjectionMatrix();
    }

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0x000000, 1);

    let lastTime = 0.0;

    const windowResizeHandler = () => {
        // const {innerHeight, innerWidth} = window;
        // renderer.setSize(innerWidth, innerHeight);
        const {x, y} = renderer.getSize(new Vector2());
        material.uniforms.u_resolution.value.x = x;
        material.uniforms.u_resolution.value.y = y;
        camera.updateProjectionMatrix();
    };
    windowResizeHandler();
    window.addEventListener("resize", windowResizeHandler);

    const geometry = new PlaneGeometry(2, 2);
    const plane = new Mesh(geometry, material);

    plane.position.z = -1;

    scene.add(plane);

// @ts-ignore
// eslint-disable-next-line no-restricted-globals
    addEventListener("mousewheel", (event: WheelEvent) => {
        scrollAmount += event.deltaY;
    });

    const render: (time?: number) => void = (timeStamp) => {
        if (!timeStamp) return;
        const deltaTime = timeStamp - lastTime;
        lastTime = timeStamp;

        smoothedScroll -= (smoothedScroll - scrollAmount) * 0.003 * deltaTime;

        renderer.render(scene, camera);
        if (!paused.paused) {
            material.uniforms.u_time.value = timeStamp / 1000 + smoothedScroll / 300;
        }

        material.uniforms.u_scrollpos.value = smoothedScroll;
    }

    function cleanup() {

    }

    return { render, cleanup }

}