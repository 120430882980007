import {main} from '../shader';
import {Paper} from "react-three-paper";
import logo from '../img/strive-logo.svg';
import React from "react";

export const Hero = () => {
    return (<div className="hero">
        <Paper script={main} />
        <div className="hero-content">
            <img src={logo} alt="Strive Services" id={`hero-logo`} />
        </div>
    </div>)
}