import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {oplTheme} from '@striveservicesllc/opl-mui';
import '@striveservicesllc/opl-mui/dist/index.css';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const striveTheme = createTheme(oplTheme, {
    palette: {
        mode: 'dark',
        primary: {
            main: '#8cc63f'
        },
        secondary: {
            main: '#90D6F4'
        }
    },
    typography: {
        h1: {
            color: "#8cc63f"
        },
        h2: {
            color: "#9dd149"
        },
        h3: {
            color: "#a7d957"
        },
        h4: {
            color: "#bae673"
        },
        h5: {
            color: "#c2e68a"
        },
        h6: {
            color: "#c7e695"
        },
    }
})
root.render(
  <React.StrictMode>
      <ThemeProvider theme={striveTheme}>
          <CssBaseline />
          <App />
      </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
