import {AppBar, Icon, SvgIcon} from "@mui/material";
import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArchiveIcon from '@mui/icons-material/Archive';
import striveIcon  from '../img/strive-icon.svg';
import { Link as RouterLink } from "react-router-dom";

export const Header = () => {

    return (<Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="primary"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                    >
                        <Icon>
                            <img src={striveIcon} />
                        </Icon>
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>
    )
}