import React from 'react';
import './App.css';
import {Hero} from "./component/Hero";
import {Header} from "./component/Header";


function App() {
    return (
        <>
            <Header/>
            <Hero/>
        </>
    );
}

export default App;
